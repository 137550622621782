window.jQuery = window.$ = require('jquery');
var processInclude = require('base/util');
var utils = require('./utils');
var debounce = require('lodash/debounce');
require('slick-carousel/slick/slick');
import clientSideRender from './clientSideRender';

// This updates the webpack static path which enable async component loading
// eslint-disable-next-line no-underscore-dangle
__webpack_public_path__ = __staticPath__ + 'js/';

/**
 * set scrollbar width in css for drawer animation and avoid page shift
 */
function setScrollbarwidth() {
    const documentWidth = document.documentElement.clientWidth;
    const windowWidth = window.innerWidth;
    const scrollBarWidth = windowWidth - documentWidth;
    document.documentElement.style.setProperty('--scrollbarwidth', `${scrollBarWidth}px`);
}

/**
 * handle all data-toggle=modal to have animation in place.
 */
function handleModalAnimation() {
    $('body').on('show.bs.modal', '.modal-animated', function () {
        const $modal = $(this);

        $modal.on('hidePrevented.bs.modal', e => {
            utils.closeModal($modal, e);
        });

        $modal.on('hide.bs.modal', e => {
            utils.closeModal($modal, e);
        });
    });
}

// eslint-disable-next-line no-undef
$(function () {
    processInclude(require('base/components/cookie'));
    processInclude(require('base/components/consentTracking'));
    processInclude(require('base/components/collapsibleItem'));
    processInclude(require('./components/customerService'));
    processInclude(require('base/components/clientSideValidation'));
    processInclude(require('base/components/countrySelector'));
    processInclude(require('base/components/toolTip'));
    processInclude(require('./components/notification'));

    $(document).on('ftr:customerWebIdReady', function (e, webId) {
        window.sessionStorage.removeItem('webId');
        if ($('.global-footer__wrapper').length) {
            $('#webiddesk').html('Web ID: ' + webId);
            $('#webidmob').html('Web ID: ' + webId);
        } else {
            window.sessionStorage.setItem('webId', webId);
        }
    });

    setScrollbarwidth();
    utils.setWindowHeight();
    window.addEventListener('resize', debounce(() => {
        utils.setWindowHeight();
        $('body').trigger('window:resized');
    }), 20);

    handleModalAnimation();
    utils.toggleInteractionMode();
    clientSideRender.mountAllComponents();
    $('body').on('component:updated', (event, component, id) => {
        clientSideRender.mountComponent(component, id);
    });

    // add no touch class for devices which support no touch. used for styling
    if (!utils.isTouchEnabled()) {
        $('html').addClass('no-touch');
    }

    // A global logic to reset PLP pos if user navigated anywhere else from PLP other than PDP
    // included search to prevent from deleted position, it will be deleted in searchjs after scrolling
    if (!['product', 'search'].includes(utils.getCurrentPageName()) && utils.plpScrollPosExists()) {
        // remove PLP scroll pos
        utils.resetPLPScrollPos();
    }
});

require('./thirdParty/bootstrap');
require('./components/spinner');
require('jquery-validation');

// Handle the broken image error.
window.onImageError = utils.onImageError;

// redefine cookieset
window.cookieSet = utils.cookieSet();
