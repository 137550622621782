let toastTimeoutInstance;

/**
 * Toast Notification close action.
 */
function closeToastWithAnimation($toast) {
    if ($toast) {
        if (toastTimeoutInstance) clearTimeout(toastTimeoutInstance);
        $('.ds-toast').addClass('close');
        toastTimeoutInstance = setTimeout(() => $toast.remove(), 100);
    }
}

module.exports = {
    initialize: () => {
        $('body').on('click', '[data-close]', (event) => {
            let $toast = $(event.target).parent('.ds-toast');
            closeToastWithAnimation($toast);
        });


        $('body').on('notification:close', (event, isAnimated) => {
            $('.ds-toast').map((index, elem) => {
                if (isAnimated) {
                    closeToastWithAnimation(elem);
                } else {
                    $(elem).remove();
                }
            });
        });
    }
};
