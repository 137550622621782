window.jQuery = require('jquery');
window.$ = window.jQuery;
var main = require('base_global/main');
var processInclude = require('base/util');

$(document).ready(function () {
    processInclude(require('./tealiumEvent'));
});

module.exports = main;
