'use strict';
window.PVH = window.PVH || {};
window.PVH.componentInstance = {};

/**
 * Make the first alphabet of the string to smaller case
 * @param {String} str component name string
 * @returns {String}
 */
function makeFirstLetterLowercase(str) {
    if (str.length === 0) {
        return str;
    }

    return str.charAt(0).toLowerCase() + str.slice(1);
}

/**
 * Import component dynamically
 * @param {String} componentName Comopnent name
 * @returns {Promise}
 */
function importComponent(componentName) {
    return import(/* webpackChunkName: "component" */`./components/${makeFirstLetterLowercase(componentName)}`);
}

/**
 * Mount component based on component element
 * @param {DOM} component component element
 * @param {String} id Component ID
 * @returns {Void}
 */
function mountComponent(component, id) {
    if (!component) return;

    // Adding 'enabled' class to component after mount
    component.classList.add('enabled');

    const componentName = component.dataset.comp;
    let componentId;
    if (id && id.split('-').length > 1) {
        componentId = id;
    } else {
        componentId = id ? `${componentName}-${id}` :component.id;
    }

    try {
        // Find existing instace of the same component, delete and recreate a new instance
        const componentInstanceExists = window.PVH.componentInstance[componentId];

        if(componentInstanceExists) {
            delete window.PVH.componentInstance[componentId];
        }
    } catch (error) {}

    if(!componentId) {
        console.error(`Component ${componentName} missing unique identifier`);
    }

    // TODO : Dynamic import component based on component Name
    importComponent(componentName).then((Comp) => {
        const instance = new Comp.default(component, componentId);
        window.PVH.componentInstance[instance.getComponentId()] = instance;
    });
}

/**
 * Generates random hexadecimal numbers for component ID
 */
function getHexaNumb() {
    return Math.floor(Math.random() * 0xffffff).toString(16).padEnd(6, "0")
}

/**
 * Intersection observer for component to enable on view
 */
function loadComponentOnView (selector, callback) {
    if (selector) {
        var options = {
            root: null,
            rootMargin: '400px 0px 500px 0px',
            threshold: 0
        };

        function handleIntersect(entries) {
            entries.forEach((entry) => {
                if (entry.isIntersecting || entry.target.dataset.noobserve) {
                    callback(entry.target);
                }
            });
        }
        let componentObserver = new IntersectionObserver(handleIntersect, options);
        var components = document.querySelectorAll(selector);
        components.forEach(function (component) {
            componentObserver.observe(component);
            component.classList.add('io');
        });
    }
}


/**
 * Mount all components in page
 */
function mountAllComponents(componentType) {
    let componentSelector = ':not(.enabled)[data-comp]';
    if (componentType) {
        componentSelector = `:not(.enabled)[data-comp=${componentType}]`;
    }
    loadComponentOnView(componentSelector, (component) => {
        if(component.classList.contains('enabled')) return;
        var componentId = getHexaNumb();
        mountComponent(component, componentId);
    });
}

window.PVH.mountAllComponents = mountAllComponents;
window.PVH.mountComponent = mountComponent;

export default {
    mountAllComponents,
    mountComponent
};
