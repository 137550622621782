'use strict';

/* customer service phone number configured on site preference. */
var phone = $('.csphone_config').html();
var maoReturnFee = $('.mao-returnfee_config').html();

/**
 * Updates customer service phone number configured on site preference.
 */
/* JQuery function */
/*
function updateCustomerServicePhone() {
    $('.cs-phone').empty().html(phone);
}
*/

/* Javascript function*/
// eslint-disable-next-line
/**
 * Updates customer service phone number configured on site preference.
 */
function updateCustomerServicePhone() {
    var elements = document.getElementsByClassName('cs-phone');
    for (var i = 0; i < elements.length; i++) {
        elements[i].innerHTML = '';
        elements[i].innerHTML += phone;
    }
}


/* Javascript function*/
// eslint-disable-next-line
/**
 * Updates MAO Return Fee configured on site preference.
 */
function updateMaoReturnFee() {
    var elements = document.getElementsByClassName('mao-returnfee');
    for (var i = 0; i < elements.length; i++) {
        elements[i].innerHTML = '';
        elements[i].innerHTML += maoReturnFee;
    }
}

module.exports = function () {
    /* JQuery event */
/*
    $(document).on('load', function () {
        updateCustomerServicePhone();
    });
*/
	/* Javascript element handling*/
    /* Javascript element handling*/
    window.addEventListener('load', () => {
        updateCustomerServicePhone();
        if (!!maoReturnFee) {
            updateMaoReturnFee();
        }
    });
};
