'use strict';

// default options
const options = {
    target: '#maincontent',
    loaderDelay: 1000
};



/**
 * Show a spinner inside a given element
 * @param {element} $target - Element to block by the veil and spinner.
 *                            Pass body to block the whole page.
 * @param {boolean} stickySpinner - parameter of function addSpinner which is false by default
 */
function addSpinner($target, stickySpinner = false) {
    //Check for two or more active loaders
    if ($target.find('.veil').length > 0){
        return;
    }

    var $veil = $('<div class="veil"><div class="underlay"></div></div>');
    $veil.append('<div class="spinner"></div>');

    // make sure if any template is missing maincontent then fallback to body
    if ($target.length <= 0) {
        // eslint-disable-next-line no-param-reassign
        $target = $('body');
    }

    if ($target.get(0).tagName === 'IMG') {
        $target.after($veil);
        $veil.css({ width: $target.width(), height: $target.height() });
        if ($target.parent().css('position') === 'static') {
            $target.parent().css('position', 'relative');
        }
    } else {
        $target.append($veil);
        if ($target.css('position') === 'static') {
            $target.parent().css('position', 'relative');
            $target.parent().addClass('veiled');
        }
        if ($target.get(0).tagName === 'BODY' || $target.attr('id') === 'maincontent') {
            $veil.find('.spinner').css('position', 'fixed');
        }

        if (stickySpinner) {
            let { height } = $veil[0].getBoundingClientRect();
            let windowHeight = window.innerHeight;

            // if the veil height is more than window height, make it sticky
            if (height > windowHeight) {
                $veil.find('.spinner').css('position', 'fixed');
            }
        }

        // show spinner after some time
        if (options.loaderDelay > 0) {
            setTimeout(() => {
                $veil.find('.spinner').css('display', 'block');
            }, options.loaderDelay);
        } else {
            $veil.find('.spinner').css('display', 'block');
        }
    }
    $veil.click(function (e) {
        e.stopPropagation();
    });
}

/**
 * Remove existing spinner
 * @param  {element} $veil - jQuery pointer to the veil element
 */
function removeSpinner($veil) {
    if ($veil.parent().hasClass('veiled')) {
        $veil.parent().css('position', '');
        $veil.parent().removeClass('veiled');
    }
    $veil.off('click');
    $veil.remove();
}

// element level spinner:
$.fn.spinner = function () {
    var $element = $(this);
    var Fn = function () {
        this.start = function (stickySpinner) {
            if ($element.length) {
                addSpinner($element, stickySpinner);
            }
        };
        this.stop = function () {
            if ($element.length) {
                var $veil = $('.veil');
                removeSpinner($veil);
            }
        };
    };
    return new Fn();
};

// page-level spinner:
$.spinner = function () {
    var Fn = function () {
        this.start = function () {
            addSpinner($(`${options.target}`));
        };
        this.stop = function () {
            removeSpinner($('.veil'));
        };
    };
    return new Fn();
};
